import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Role from "./Role";
import AgencyTests from "./AgencyTests";
import User from "./User";
import Coupon from "./Coupon";
import Section from "./Section";
import Machine from "./Machine";
import Equipment from "./Equipment";
import CheckList from "./CheckList";
import Maintenance from "./Maintenance";
import Department from "./Department";
import Appointment from "./Appointment";
import storage from "redux-persist/lib/storage";
import BranchLookUp from "./BranchLookUp";
import TestDirectory from "./TestDirectory";
import DiscontinuedTestDirectoryService from "./DiscontinuedTestDirectories";
import TestRegistration from "./TestRegistration";
import Patient from "./Patient";
import ClientCompany from "./ClientCompany";
import BranchTestCapacity from "./BranchTestCapacity";
import ClientCompanyTestDirectory from "./ClientCompanyTestDirectory";
import newOrder from "./NewOrder";
import OrderMadeByGuestUsers from "./OrderMadeByGuestUsers";
import InPersonDelivery from "./InPersonDeliver";
import Financials from "./Financials";
import FinancialReportUpload from "./FinancialReport";
import { persistReducer } from "redux-persist";
import Notyetreleased from "./Notyetreleased";
import Released from "./Released";
import SampleAccepted from "./SampleAccepted";
import PatientResult from "./PatientResult";
import IncompleteResults from "./IncompleteResults";
import Discrepancy from "./Discrepancy";
import DiscrepancyApproved from "./DiscrepanciesStatus/DiscrepancyApproved";
import DiscrepancyDeclined from "./DiscrepanciesStatus/DiscrepancyDeclined";
import DiscrepancyPending from "./DiscrepanciesStatus/DiscrepancyPending";
import ApprovedOrders from "./OrderStatus/ApprovedOrders";
import DeclinedOrders from "./OrderStatus/DeclinedOrders";
import PendingOrders from "./OrderStatus/PendingOrders";
import StatOrders from "./StatOrders";
import AllOrders from "./OrderStatus/AllOrders";
import OrderedOrders from "./OrderStatus/OrderedOrders";
import TopAgencies from "./TopAgencies";
import TopTests from "./TopTests";
import PendingResultsObx from "./PendingResultsObx";
import topPanels from "./TopPanels";
import DashboardReports from "./AdminDashboardReports/DashboardReports";
import TopBranchesForWalkinCustomers from "./TopBranchesForWalkinCustomer";
import PanicandAbnormalTestResults from "./PanicandAbnormalTestResults";
import PatientTestResultHistory from "./PatientTestResultHistory";
import PatientSearch from "./PatientsSearch";
import UploadFile from "./UploadFile";
import UploadCsvFile from "./UploadCsvFile";
import MicroBiology from "./MicroBiology";
import IclPay from "./IclPay";
import DailyReleasedTests from "./DailyReleasedTests";
import SingleReleasedDailyReleasedTests from "./SingleReleasedDailyReleasedTests";
import DailyDepartmentReleasedTests from "./DailyDepartmentReleasedTests";
import CompletedResults from "./CompletedResults";
import { SampleInProcess } from "./SampleInProcess";
import FaultResults from "./FaultResults";
import ReferableOrders from "./ReferableOrders";
import TestobxPerTechnician from "./TestObxPerTechnician";
import TatApproaching from "./TATApproaching";
import SpecimenTracking from "./SpecimenTracking";
import PickUpRequest from "./PickupRequest";
import TbmicroResults from "./TbMicro";
import CoreLabResults from "./CoreLab";
import Pathology from "./Pathology";
import Cost from "./Cost";
import FetchWebLedgerResults from "./WebLedgerInfo";
import DailyFinancialSummary from "./DailyFinancialSummary";
import ForgotPassoword from "./ForgotPassword";
import Notifications from "./Notifications";
import UserPreference from "./UserPreferences";
import Logistics from "./Logistics";
import DoctorsList from "./DoctorsList";
import agencyDoctorsReducer from "./FetchAgencyDoctors";
import searchDoctorsReducer from "./SearchDoctors";
import addDoctorsByClientCompanyReducer from "./addDoctorsByClinetCompany";
import RedrawSamples from "./OrderStatus/RedrawSamples";
import StatResults from "./StatResults";
import SampleCollectedOrders from "./OrderStatus/SampleCollectedOrders";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["appointment", "branchLookUp"],
};

const reducers = combineReducers({
  theme: Theme,
  role: Role,
  agencyTests: AgencyTests,
  user: User,
  coupon: Coupon,
  section: Section,
  machine: Machine,
  equipment: Equipment,
  checkList: CheckList,
  maintenance: Maintenance,
  auth: Auth,
  department: Department,
  appointment: Appointment,
  branchLookUp: BranchLookUp,
  testDirectory: TestDirectory,
  discontinuedTestDirectoryService: DiscontinuedTestDirectoryService,
  testRegistration: TestRegistration,
  patient: Patient,
  branchTestCapacity: BranchTestCapacity,
  clientCompany: ClientCompany,
  clientCompanyTestDirectory: ClientCompanyTestDirectory,
  newOrder: newOrder,
  orderMadeByGuestUsersState: OrderMadeByGuestUsers,
  inPersonDelivery: InPersonDelivery,
  financial: Financials,
  finanicialReport: FinancialReportUpload,
  cost: Cost,
  notyetreleaseds: Notyetreleased,
  sampleInProcess: SampleInProcess,
  releaseds: Released,
  sampleAccepted: SampleAccepted,
  patientresult: PatientResult,
  completedResults: CompletedResults,
  incompleteResults: IncompleteResults,
  discrepancy: Discrepancy,
  discrepancyApproved: DiscrepancyApproved,
  discrepancyDeclined: DiscrepancyDeclined,
  discrepancyPending: DiscrepancyPending,
  statResults: StatResults,
  approvedOrders: ApprovedOrders,
  declinedOrders: DeclinedOrders,
  pendingOrders: PendingOrders,
  statOrders: StatOrders,
  allOrders: AllOrders,
  orderedOrders: OrderedOrders,
  redrawSamples: RedrawSamples,
  sampleCollectedOrders: SampleCollectedOrders,
  topagencies: TopAgencies,
  toptests: TopTests,
  pendingResultsObx: PendingResultsObx,
  dashboardReports: DashboardReports,
  topPanels: topPanels,
  topBranchesForWalkinCustomers: TopBranchesForWalkinCustomers,
  panicandabnormaltestresults: PanicandAbnormalTestResults,
  patientTestResultHistory: PatientTestResultHistory,
  patientSearch: PatientSearch,
  uploadFile: UploadFile,
  uploadCsvFile: UploadCsvFile,
  microBiology: MicroBiology,
  iclPay: IclPay,
  dailyReleasedTests: DailyReleasedTests,
  dailyDepartmentReleasedTests: DailyDepartmentReleasedTests,
  singleReleasedDailyReleasedTests: SingleReleasedDailyReleasedTests,
  faultResults: FaultResults,
  referableOrders: ReferableOrders,
  tesObxPerTechnician: TestobxPerTechnician,
  tatApproaching: TatApproaching,
  specimenTracking: SpecimenTracking,
  dailyFinancialSummary: DailyFinancialSummary,
  pickUpRequest: PickUpRequest,
  tbMicro: TbmicroResults,
  coreLab: CoreLabResults,
  pathology: Pathology,
  fetchwebledgerresults: FetchWebLedgerResults,
  forgotPassword: ForgotPassoword,
  notifications: Notifications,
  userPreference: UserPreference,
  logistics: Logistics,
  doctors: DoctorsList,
  agencyDoctors: agencyDoctorsReducer,
  searchDoctors: searchDoctorsReducer,
  addDoctorsByClinetCompany: addDoctorsByClientCompanyReducer,
});

export default persistReducer(persistConfig, reducers);
